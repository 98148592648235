import m from "mithril";

import globalState from "../../../../../state";

import loadingSpinner     from "../../../../../components/loading-spinner";
import { cellFormatters } from "../../../../../util/formatters";
import { states }         from "../../../../../util/purchase-history";

import css from "./table.css";

const currencySymbols = {
    USD : "$",
    EUR : "€",
    GBP : "£"
};

// can't build these paths programmatically or else hashing won't work
const paymentTypeIcons = {
    0 : "https://account.staticwars.com/pages/content/img/braintree.svg",
    1 : "https://account.staticwars.com/pages/content/img/paypal.svg",
    2 : "https://account.staticwars.com/pages/content/img/credit-card.svg",
    4 : "https://account.staticwars.com/pages/content/img/adyen.svg"
};

export const price = {
    view({ attrs }) {
        const { amount, currencyCode, hideSmall } = attrs;

        return m("span", { class : css.price },
            currencySymbols[currencyCode],
            amount,
            " ",
            m("span", { class : hideSmall ? css.currencyHideSmall : css.currency }, currencyCode)
        );
    }
};

export const details = {
    oninit({ attrs }) {
        const { order_key } = attrs.purchase;

        globalState.purchaseHistory.getDetails(order_key);
    },
    view({ attrs }) {
        const { purchaseHistory } = globalState;
        const gcI18n              = globalState.i18n.purchaseHistory;

        return [
            // this row is hidden with CSS but it keeps the table striping correct
            m("tr", { class : css.dummyRow }, m("td", { colspan : "100%" })),

            m("tr",
                m("td", { colspan : "100%" },

                    purchaseHistory.details &&
                        m("div", { class : css.detailsContainer },
                            m("table", { class : css.detailsTable },
                                m("tbody",

                                    // goods names and prices
                                    purchaseHistory.details.line_items.map(lineItem =>
                                        m("tr",
                                            m("td", { class : css.goods_names }, lineItem.goods_name),
                                            m("td",
                                                m(price, {
                                                    amount       : lineItem.goods_sale_price,
                                                    currencyCode : purchaseHistory.details.currency_code
                                                })
                                            )
                                        )
                                    ),

                                    // subtotal
                                    m("tr", { class : css.subtotal_row },
                                        m("td", m("span", gcI18n.labels.subtotal)),
                                        m("td",
                                            m(price, {
                                                amount       : purchaseHistory.details.subtotal,
                                                currencyCode : purchaseHistory.details.currency_code
                                            })
                                        )
                                    ),

                                    // sales tax
                                    m("tr", { class : css.sales_tax_row },
                                        m("td",
                                            m("span",
                                                gcI18n.labels.sales_tax,
                                                m("span", { class : css.vat },
                                                    `(${purchaseHistory.details.taxes.sales_tax_rate})`
                                                )
                                            )
                                        ),
                                        m("td",
                                            m(price, {
                                                amount       : purchaseHistory.details.taxes.sales_tax,
                                                currencyCode : purchaseHistory.details.currency_code
                                            })
                                        )
                                    ),

                                    // grand total
                                    m("tr", { class : css.grand_total_row },
                                        m("td", gcI18n.labels.grand_total),
                                        m("td",
                                            m(price, {
                                                amount       : purchaseHistory.details.grand_total,
                                                currencyCode : purchaseHistory.details.currency_code
                                            })
                                        )
                                    )
                                )
                            ),

                            // Payment method, order key, cart location
                            m("div", { class : css.detailsFooter },

                                m("p", { class : css.payment_method },
                                    gcI18n.labels.payment_type,
                                    gcI18n.labels.colon,
                                    " ",
                                    m("img", { src : paymentTypeIcons[attrs.purchase.payment_type] }),
                                    " ",
                                    gcI18n.labels.payment_types[attrs.purchase.payment_type]
                                ),

                                // responsive, only shown when table column is hidden
                                m("p", { class : css.order_key },
                                    gcI18n.labels.order_key,
                                    gcI18n.labels.colon,
                                    " ",
                                    attrs.purchase.order_key
                                ),

                                // responsive, only shown when table column is hidden
                                m("p", { class : css.cart_location },
                                    gcI18n.labels.cart_location,
                                    gcI18n.labels.colon,
                                    " ",
                                    cellFormatters.cart_location(attrs.purchase.cart_location)
                                ),

                                // responsive, only shown when table column is hidden
                                m("p", { class : css.status },
                                    gcI18n.labels.status,
                                    gcI18n.labels.colon,
                                    " ",
                                    cellFormatters.status(attrs.purchase.status)
                                )
                            )

                        ),

                        purchaseHistory.state === states.LOADING_DETAILS &&
                            m(loadingSpinner, { fixed : false })
                )
            )
        ];
    }
};
