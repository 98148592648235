import m from "mithril";

import globalState from "../state";

const formatter = new Intl.DateTimeFormat();

export const cellFormatters = {
    goods_names(value, { type = "html" } = {}) {
        switch (type) {
            case "html":
                return m.trust(value.join("<br>"));

            case "csv":
                return `"${value.join(",")}"`;

            default:
                return value.join(",");
        }
    },
    cart_location(value) {
        return globalState.i18n.purchaseHistory.labels.cart_locations[value];
    },

    payment_type(value) {
        return globalState.i18n.purchaseHistory.labels.payment_types[value];
    },


    // only show "success" if the order is completed
    status(value) {
        return globalState.i18n.purchaseHistory.labels.statuses[value];
    }
};

/**
 * @param {String} moneyString
 * @returns {String}
 */
export function formatMoney(moneyString) {
    return Number(moneyString).toFixed(2);
}

/**
 * @param {String} date
 * @returns {String} eg 12/31/2020
 */
export function formatDate(date) {
    return formatter.format(new Date(date));
}

/**
 * Format tax rate
 * @param {String} taxRate 0.02000
 * @returns {String} 2.00%
 */
export function formatTaxRate(taxRate) {
    return `${(taxRate * 100).toFixed(2)}%`;
}
