import globalState from "../state";

import { cellFormatters } from "./formatters";

const csvColumns = [
    "registered",
    "goods_names",
    "order_key",
    "cart_location",
    "status",
    "subtotal",
    "sales_tax",
    "sales_tax_rate",
    "grand_total",
    "currency_code",
    "payment_type"
];

/**
 * Converts an array of orders to a CSV string
 * @param {Array<import("./get-purchase-history").Orders} orders
 * @returns {String}
 */
export function convertToCSV(orders) {
    if (!orders) {
        return;
    }

    const gcI18n = globalState.i18n.purchaseHistory;

    const header = csvColumns.map(columnKey => gcI18n.labels[columnKey] || columnKey);

    const rows = orders.map(purchase => {
        return csvColumns.map(columnKey => {
            const value = purchase[columnKey];

            return cellFormatters[columnKey] ? cellFormatters[columnKey](value, { type : "csv" }) : value;
        });
    });

    return [ header, ...rows ].map(row => row.join(",")).join("\n");
}
