import m from "mithril";

import globalState from "../../../../../state";

import { states }         from "../../../../../util/purchase-history";
import { cellFormatters } from "../../../../../util/formatters";

import { details, price } from "./details";

import css from "./table.css";

const tableColumns = [
    "registered",
    "goods_names",
    "order_key",
    "cart_location",
    "status",
    "grand_total"
];

const skeleton = {
    view() {
        const { purchaseHistory } = globalState;

        return Array(purchaseHistory.page_size).fill().map((_, rowIndex) =>
            m("tr", { class : css.skeletonRow },
                // m("td", { colspan : "100%" }, " ")
                tableColumns.map((columnKey, columnIndex) =>
                    m("td", {
                        class : css[columnKey],
                        style : `--delay: ${(rowIndex + columnIndex) * 0.1}s`
                    }, " ")
                ),
                m("td", { class : css.action })
            )
        );
    }
};


export default {
    onbeforeupdate({ state }) {
        // don't persist expanded row when returning to a page
        if (state.showRowPage !== globalState.purchaseHistory.page) {
            state.showRowId = null;
        }
    },
    view({ state }) {
        const gcI18n              = globalState.i18n.purchaseHistory;
        const { purchaseHistory } = globalState;

        if (!purchaseHistory.state === states.EMPTY) {
            return null;
        }

        return m("div", { class : css.tableContainer },

            // download csv button
            purchaseHistory.csv &&
                m("a", {
                        class        : css.download,
                        href         : `data:text/csv;charset=utf-8,${encodeURIComponent(purchaseHistory.csv)}`,
                        "aria-label" : gcI18n.download,
                        download     : gcI18n.filename.replace("{page}", purchaseHistory.page)
                    },
                    m("img", { src : "https://account.staticwars.com/pages/content/img/download.svg", alt : "" })
                ),

            // main table
            m("table", {
                    "data-test" : `purchase-history-${purchaseHistory.state}`,
                    class       : css.table
                },

                m("thead",
                    m("tr",
                        tableColumns.map(columnKey =>
                            m("th", { class : css[columnKey] }, gcI18n.labels[columnKey])
                        ),
                        m("th", { class : css.action }, m.trust("&nbsp;"))
                    )
                ),

                m("tbody", {
                        "data-test" : `purchase-history-page-${purchaseHistory.page}`
                    },

                    // skeleton loading rows
                    purchaseHistory.state === states.LOADING &&
                        m(skeleton),

                    // data & details rows
                    purchaseHistory.orders?.map(purchase => {
                        const showRowDetails = state.showRowId === purchase.order_key;

                        return [
                            m("tr",

                                // main row data
                                tableColumns.map(columnKey => {
                                    let content = purchase[columnKey];


                                    if (columnKey === "grand_total") {
                                        content = m(price, {
                                            amount       : content,
                                            currencyCode : purchase.currency_code,
                                            hideSmall    : true
                                        });
                                    } else if (cellFormatters[columnKey]) {
                                        content = cellFormatters[columnKey](content);
                                    }

                                    return m("td", { class : css[columnKey] }, content);
                                }),

                                //  show/hide details button
                                m("td", { class : showRowDetails ? css.actionExpanded : css.action },
                                    m("button", {
                                            "aria-label" : gcI18n.details[showRowDetails ? "showLess" : "showMore"],
                                            onclick() {
                                                state.showRowId   = showRowDetails ? null : purchase.order_key;
                                                state.showRowPage = purchaseHistory.page;
                                            }
                                        },
                                        m("img", { src : "https://account.staticwars.com/pages/content/img/arrow.png", alt : "" })
                                    )
                                )
                            ),

                            // details row & sub-table
                            showRowDetails && m(details, { purchase })
                        ];
                    })
                )
            )
        );
    }
};
