import m from "mithril";

import { sub } from "template";

import globalState                              from "../../../../../state";
import { errorStates, PurchaseHistory, states } from "../../../../../util/purchase-history";

import table      from "./table";
import pagination from "./pagination";

import css from "../purchase-history.css";

const buySiteButtons = {
    view() {
        const gcI18n     = globalState.i18n.purchaseHistory;
        const links      = {
            Portal : [ "gw2", "gw1" ],
            Steam  : [ "gw2" ],
            Epic   : [ "gw2" ]
        }[globalState.provider];
        const installKey = `${globalState.provider.toLowerCase()}Install`;

        return m("div", { class : css.buySiteCtas },
            m("p", m.trust(gcI18n.buySite[globalState.provider])),

            m("div", { class : css.storeButtons },
                links.map(key =>
                    m("a", {
                            class : css.storeButton,
                            href  : globalState.getBuyLink(key)
                        },
                        gcI18n.buySiteCta[globalState.provider][key]
                    )
                )
            ),
            m("p", m.trust(sub(gcI18n.buySite.cs, { supportEmail : globalState.supportEmail }))),
            m("p", m("a", { href : globalState.urls[installKey] }, gcI18n.buySite.kb))
        );
    }
};

export default {
    oninit() {
        globalState.purchaseHistory = new PurchaseHistory({ apiUrl : globalState.urls.wapiUrl });

        if (!globalState.isSteamUser) {
            globalState.purchaseHistory.getOrders({ page : 1 });
        }
    },

    view() {
        const gcI18n              = globalState.i18n.purchaseHistory;
        const { purchaseHistory } = globalState;

        return [
            purchaseHistory.errorState === errorStates.ORDERS &&
                m("p", { class : css.error }, gcI18n.errors.loading),

            !globalState.isThirdParty && purchaseHistory.state === states.EMPTY &&
                m("p", { "data-test" : "purchase-history-empty" }, gcI18n.empty),

            globalState.isThirdParty &&
                m("p",
                    m.trust(sub(
                        gcI18n.thirdPartyHistory[globalState.provider],
                        { href : globalState.urls.purchaseHistory[globalState.provider] }
                    ))
                ),

            (globalState.isSteamUser || purchaseHistory.state === states.EMPTY) &&
                m(buySiteButtons),

            [
                states.LOADING,
                states.LOADED,
                states.LOADING_DETAILS
            ].includes(purchaseHistory.state) &&
                [
                    m(table),
                    m(pagination)

                ]
        ];
    }
};
